import { Injectable } from '@angular/core'
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http'
import { catchError, Observable, throwError } from 'rxjs'
import { AuthService } from '../services/auth.service'
import { Router } from '@angular/router'
import { environment } from '../../../../environments/environment'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

	constructor(
		private _authService: AuthService,
		private _router: Router
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const isAuthorized: boolean = this._authService.checkAuth()
		const accessToken: string = this._authService.accessToken

		if (isAuthorized) {
			if (req.url.startsWith(environment.apiUrl)) {
				req = this.addApiAuthorization(req, accessToken)
			} else {
				console.error('Неизвестный URL', req.url)
			}
		}

		return next.handle(req).pipe(
			// catchError((error: HttpErrorResponse) => this._errorHandler(error, req, next))
			catchError((error: HttpErrorResponse) => this._errorHandler(error))
		)
	}

	private addApiAuthorization(req: HttpRequest<any>, token: string): HttpRequest<any> {
		return req.clone({
			setHeaders: {
				Authorization: `Bearer ${ token }`
			}
		})
	}

	// private _errorHandler(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
	// 	if (error.status === 401) {
	// 		const refresh: string | undefined = this._authService.refreshToken
	// 		if (!refresh) {
	// 			this._authService.dropData()
	// 			this._router.navigate(['/auth/sign-in'])
	// 			return throwError(() => new Error('No refresh token available'))
	// 		}
	// 		return this._authService.updateAccessByRefresh(refresh)
	// 			.pipe(
	// 				switchMap(() => {
	// 					const accessToken: string = this._authService.accessToken
	// 					const updatedRequest: HttpRequest<any> = this.addApiAuthorization(req, accessToken)
	// 					return next.handle(updatedRequest)
	// 				}),
	// 				catchError((err: HttpErrorResponse) => {
	// 					this._authService.dropData()
	// 					this._router.navigate(['/auth/sign-in'])
	// 					return throwError(() => err)
	// 				})
	// 			)
	// 	}
	// 	return throwError(() => error)
	// }

	private _errorHandler(error: HttpErrorResponse): Observable<HttpEvent<any>> {
		if (error.status === 401) {
			this._authService.dropData()
			this._router.navigate(['/auth/sign-in'])
		}
		return throwError(() => error)
	}

}
