import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectComponent),
			multi: true
		}
	]
})
export class SelectComponent {

	@Input() label: string
	@Input() items: any[] = []
	@Input() bindLabel: string
	@Input() bindValue: string
	@Input() clearable = false
	@Input() classes = ''

	@Input() ngModel: any
	@Output() ngModelChange = new EventEmitter<any>()

	public isOpen = false

	constructor() {}

	/**
	 * Sets the value of `ngModel` property and emits `ngModelChange` event.
	 *
	 * @param {any} event - The event triggered by the ngSelect element.
	 * @return {void}
	 */
	public onNgSelectChange(event: any): void {
		this.ngModel = event
		this.ngModelChange.emit(this.ngModel)
	}

	/**
	 * Sets the value of the `isOpen` property to `true`.
	 *
	 * @param {void} - This function does not take any parameters.
	 * @return {void} - This function does not return any value.
	 */
	public onSelectOpen(): void {
		this.isOpen = true
	}

	/**
	 * A description of the entire function.
	 *
	 * @param {void} - This function does not accept any parameters.
	 * @return {void} - This function does not return any value.
	 */
	public onSelectClose(): void {
		this.isOpen = false
	}

	/**
	 * Writes a value to the ngModel property.
	 *
	 * @param {any} value - The value to write.
	 * @return {void} This function does not return a value.
	 */
	writeValue(value: any): void {
		this.ngModel = value
	}

	/**
	 * Registers a callback function to be called when the value of the ngModel changes.
	 *
	 * @param {any} fn - The callback function to be registered.
	 * @return {void} There is no return value.
	 */
	registerOnChange(fn: any): void {
		this.ngModelChange.subscribe(fn)
	}

	/**
	 * Registers a function to be called when the element is touched.
	 *
	 * @param {any} fn - The function to be registered.
	 * @return {void} This function does not return anything.
	 */
	registerOnTouched(fn: any): void {}

	/**
	 * Sets the disabled state of the component.
	 *
	 * @param {boolean} isDisabled - The new disabled state of the component.
	 * @return {void} This function does not return anything.
	 */
	setDisabledState(isDisabled: boolean): void {}

}
