import { Injectable } from '@angular/core'
import { CoreSidebarComponent } from './core-sidebar.component'

@Injectable()
export class CoreSidebarService {
	// -----------------------------------------------------------------------------------------------------
	// @ Private Properties
	// -----------------------------------------------------------------------------------------------------
	private registry: { [key: string]: CoreSidebarComponent } = {}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Retrieves a sidebar component from the registry based on the given key.
	 *
	 * @param {string} key - The key to identify the sidebar component in the registry.
	 * @return {CoreSidebarComponent | undefined} The sidebar component associated with the key, or undefined if not found.
	 */
	getSidebarRegistry(key: string): CoreSidebarComponent | undefined {
		// Check if the sidebar registered
		if (!this.registry[key]) {
			console.warn(`The sidebar with the key '${key}' doesn't exist in the registry.`)
			return
		}

		// Return the sidebar
		return this.registry[key]
	}

	/**
	 * Set a sidebar in the registry with the given key.
	 *
	 * @param key { string } - The unique key for the sidebar.
	 * @param sidebar { any } - The sidebar to be set.
	 */
	setSidebarRegistry(key: string, sidebar: any): void {
		// Check if the key already exists in the registry
		if (this.registry[key]) {
			console.error(
				`The sidebar with the key '${key}' already exists. Either unregister it first or use a unique key.`
			)

			return
		}

		// Set the sidebar in the registry
		this.registry[key] = sidebar
	}

	/**
	 * Remove the sidebar from the registry
	 *
	 * @param key { string } - The key of the sidebar to remove
	 */
	removeSidebarRegistry(key: string): void {
		// Check if the sidebar is registered
		if (!this.registry[key]) {
			// Print a warning message if the sidebar doesn't exist in the registry
			console.warn(`The sidebar with the key '${key}' doesn't exist in the registry.`)
		}

		// Unregister the sidebar by deleting it from the registry
		delete this.registry[key]
	}
}
