import { NgModule } from '@angular/core'
import { ClosePositionSidebarComponent } from './close-position-sidebar.component'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatTabsModule } from '@angular/material/tabs'
import { CorePipesModule } from '../../../../../pipes/pipes.module'
import { CounterModule } from '../../../../../components/counter/counter.module'
import { NgxSliderModule } from 'ngx-slider-v2'
import { TableModule } from '../../../../../components/table/table.module'
import { OrderPipesModule } from '../../../../order-entity/pipes/order-pipes.module'
import { SelectModule } from '../../../../../components/select/select.module'
import {
	ConfirmCancelOrderSidebarModule
} from '../../../../order-entity/components/sidebars/confirm-cancel-order-sidebar/confirm-cancel-order-sidebar.module'
import { CoreSidebarModule } from '../../../../../components/core-sidebar/core-sidebar.module'

@NgModule({
	declarations: [
		ClosePositionSidebarComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		MatTabsModule,
		CorePipesModule,
		CounterModule,
		NgxSliderModule,
		ReactiveFormsModule,
		TableModule,
		OrderPipesModule,
		SelectModule,
		ConfirmCancelOrderSidebarModule,
		CoreSidebarModule
	],
	exports: [
		ClosePositionSidebarComponent
	],
	providers: []
})
export class ClosePositionSidebarModule { }
