import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs'
import { ClosePositionInterface } from '../interfaces/close-position.interface'
import {
	PositionsResponseInterface
} from '../interfaces/positions-response.interface'
import { PositionInterface } from '../interfaces/position.interface'
import { WebSocketService } from '../../../services/web-socket.service'
import { UserService } from '../../user-entity/services/user-service'
import { DealInterface } from '../../deal-entity/interfaces/deal.interface'
import { PositionsHelperService } from './positions-helper.service'
import { environment } from '../../../../environments/environment'

@Injectable( { providedIn: 'root' } )
export class PositionService {

	public onGetSocketPositions: BehaviorSubject<PositionInterface[] | null> = new BehaviorSubject<PositionInterface[] | null>(null)

	constructor(
		private _http: HttpClient,
		private webSocketService: WebSocketService,
		private userService: UserService,
		private positionHelperService: PositionsHelperService
	) {}

	/**
	 * Получает все позиции
	 *
	 * @return {Observable<PositionsResponseInterface>} The positions response.
	 */
	public getAllPositions(): Observable<PositionsResponseInterface> {
		return this._http.get<PositionsResponseInterface>(`${environment.apiUrl}/alpaca/get_all_positions/`)
	}

	/**
	 * Закрывает позицию
	 *
	 * @param {ClosePositionInterface} data - The data object containing information about the position to be closed.
	 * @return {Observable<ClosePositionInterface>} An observable that emits the closed position information.
	 */
	public closePosition(data: ClosePositionInterface): Observable<ClosePositionInterface> {
		return this._http.post<ClosePositionInterface>(`${environment.apiUrl}/orders/close_positions/`, data)
	}

	/**
	 * Открывает сокет для позиций
	 *
	 * @return {Observable<PositionInterface[]>} The positions received from the socket.
	 */
	public getSocketPositions(): Observable<PositionInterface[]> {
		if (!this.userService.userDetailLocal) return of([])
		this.webSocketService.connect(`${environment.socketUrl}/get_all_positions/`)

		this.webSocketService.sendMessage({
			action: 'get_positions',
			request_id: 150060530,
			account_id: this.userService.userDetailLocal.broker_id
		})

		return this.webSocketService.onMessage()
			.pipe(
				map((response: PositionInterface[]) => {
					return response.map((position: PositionInterface) => {
						return {
							...position,
							profitability_dollar: this.positionHelperService.getResultProfitability(position, 'dollar'),
							profitability_percent: this.positionHelperService.getResultProfitability(position, 'percent')
						}
					})
				}),
				tap((response: PositionInterface[]) => {
					this.onGetSocketPositions.next(response)
				})
			)
	}

	public getDeals(symbol: string, type: string): Observable<DealInterface[]> {
		const params: HttpParams = new HttpParams()
			.append('symbol', symbol.toUpperCase())
			.append('activity_type', type.toUpperCase())

		return this._http.get<DealInterface[]>(`${environment.apiUrl}/alpaca/account_activities/`, { params })
			.pipe(
				map((response: DealInterface[]) => {
					return this.positionHelperService.transformDeals(response)
				})
			)
	}

}
