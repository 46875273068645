import { Component } from '@angular/core'

@Component({
	selector: 'app-engineering-works',
	templateUrl: './engineering-works.component.html'
})
export class EngineeringWorksComponent {

	constructor() { }

}
