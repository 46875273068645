import { LoadingInterceptor } from './loading.interceptor'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'

@NgModule({
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoadingInterceptor,
			multi: true
		}
	]
})
export class LoadingModule {
}
