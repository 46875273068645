import { Injectable } from '@angular/core'
import { UserService } from '../services/user-service'
import { Resolve } from '@angular/router'
import { UserDetailInterface } from '../interfaces/user-detail.interface'
import { catchError, Observable, throwError } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http'
import {
	NotificationService
} from '../../../components/notification/core/services/notification.service'

@Injectable({ providedIn: 'root' })
export class UserDetailResolver implements Resolve<UserDetailInterface> {

	constructor(
		private _userService: UserService,
		private _notificationService: NotificationService
	) {}

	resolve(): Observable<UserDetailInterface> {
		return this._userService.getUserDetail()
			.pipe(
				catchError((error: HttpErrorResponse) => {
					this._notificationService.onShow('error', 'Пользователь', error?.error?.error.message || 'Не удалось получить пользователя')
					return throwError(error)
				})
			)
	}

}
