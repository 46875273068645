import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'searchRestCountry'
})
export class SearchRestCountryPipe implements PipeTransform {
	transform(countries: any[], searchText: string): any[] {
		if (!searchText) return countries
		searchText = searchText.toLowerCase()

		return countries.filter((country) => {
			return this._matches(country.cca2, searchText) ||
				this._matches(country.cca3, searchText) ||
				this._matches(country.idd.root, searchText) ||
				this._matches(country.name.common, searchText) ||
				(country.altSpellings && country.altSpellings.some((alt: string) => this._matches(alt, searchText)))
		})
	}

	private _matches(value: string, searchText: string): boolean {
		return value?.toLowerCase().includes(searchText)
	}
}
