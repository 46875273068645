import {
	Component,
	OnInit,
	OnDestroy,
	Input,
	Renderer2,
	ElementRef,
	ChangeDetectorRef,
	EventEmitter,
	Output
} from '@angular/core'
import { Subject } from 'rxjs'
import { CoreSidebarService } from './core-sidebar.service'
import { ScrollService } from '../../services/scroll.service'
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations'

@Component({
	selector: 'app-core-sidebar',
	templateUrl: './core-sidebar.component.html'
})
export class CoreSidebarComponent implements OnInit, OnDestroy {
	/* Input */
	@Input()
		componentName: string

	@Input()
		hideOnEsc: boolean

	@Input()
		overlayVisibility: boolean

	@Input()
		overlayClass = 'overlay'

	isOpened: boolean

	/* Output */

	// Opened changed event
	@Output()
		openedChangedEvent: EventEmitter<boolean>

	/* Private */
	private _unSubscribeAll: Subject<any> = new Subject<any>()
	private _data: any = {}
	private _overlay: HTMLElement | null = null
	private _animationPlayer: AnimationPlayer

	/**
	 * Constructor
	 *
	 **/
	constructor(
		private _renderer: Renderer2,
		private _elementRef: ElementRef,
		private _changeDetectorRef: ChangeDetectorRef,
		private _coreSidebarService: CoreSidebarService,
		private _scrollService: ScrollService,
		private _animationBuilder: AnimationBuilder
	) {
		// Set the defaults
		this.hideOnEsc = false
		this.overlayVisibility = true
		this.isOpened = false

		this.openedChangedEvent = new EventEmitter()
	}

	set data(data: any) {
		this._data = data
	}

	get data(): any {
		return this._data
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Shows the sidebar by adding the 'show' class to the native element
	 * and marking the component for change detection.
	 */
	private showSidebar(): void {
		this._renderer.addClass(this._elementRef.nativeElement, 'open')
		this._changeDetectorRef.markForCheck()
	}

	/**
	 * Hides the sidebar by removing the 'show' class from the native element
	 * and marking the change detector for check.
	 */
	private hideSidebar(): void {
		this._renderer.removeClass(this._elementRef.nativeElement, 'open')
		this._changeDetectorRef.markForCheck()
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Открывает сайдбар
	 *
	 * @return {void} The function does not return a value.
	 */
	open(): void {
		if (this.isOpened) {
			return
		}

		this.showSidebar()
		this._showOverlay()

		this.isOpened = true
		this.openedChangedEvent.emit(this.isOpened)

		this._changeDetectorRef.markForCheck()
		this._scrollService.disableScroll()
	}

	/**
	 * Закрывает сайдбар
	 *
	 * @return {void} The function does not return a value.
	 */
	close(): void {
		if (!this.isOpened) {
			return
		}

		this.hideSidebar()
		this._hideOverlay()

		this.isOpened = false
		this.openedChangedEvent.emit(this.isOpened)

		this._changeDetectorRef.markForCheck()
		this._scrollService.enableScroll()
	}

	/**
	 * Открывает или закрывает сайдбар
	 *
	 * @return {void} The function does not return a value.
	 */
	toggleOpen(): void {
		if (this.isOpened) {
			this.close()
		} else {
			this.open()
		}
	}


	/**
	 * Show Overlay
	 * @private
	 */
	private _showOverlay(): void {
		// Create the overlay element
		this._overlay = this._renderer.createElement('div')

		// Add a class to the overlay element and make it visible
		this._overlay?.classList.add(this.overlayClass)
		this._overlay?.classList.add('show')

		// If overlayVisibility is false, set the bg transparent
		if (!this.overlayVisibility) {
			this._overlay?.classList.add('bg-transparent')
		}

		// Append the overlay element to the parent element of the sidebar
		this._renderer.appendChild(this._elementRef.nativeElement.parentElement, this._overlay)

		// Overlay enter animation and attach it to the animationPlayer
		this._animationPlayer = this._animationBuilder
			.build([animate('300ms ease', style({ opacity: 1 }))])
			.create(this._overlay)

		// Play the overlay animation
		this._animationPlayer.play()

		// Add an event listener to the overlay, on click of it close the sidebar
		this._overlay?.addEventListener('click', () => {
			this.close()
		})
		// Change detector
		this._changeDetectorRef.markForCheck()
	}

	/**
	 * Hide Overlay
	 * @private
	 */
	private _hideOverlay(): void {
		// If overlay is already hidden, return
		if (!this._overlay) {
			return
		}

		// Overlay leave animation and attach it to the animationPlayer
		this._animationPlayer = this._animationBuilder
			.build([animate('300ms ease', style({ opacity: 0 }))])
			.create(this._overlay)

		// Play the overlay leave animation
		this._animationPlayer.play()

		// Once the animation is done...
		this._animationPlayer.onDone(() => {
			// If the overlay still exists...
			if (this._overlay && this._overlay.parentNode) {
				// Remove the overlay
				this._overlay.parentNode.removeChild(this._overlay)
				this._overlay = null
			}
		})
		// Change detector
		this._changeDetectorRef.markForCheck()
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Register the sidebar
		this._coreSidebarService.setSidebarRegistry(this.componentName, this)
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy(): void {
		// Remove the sidebar register
		this._coreSidebarService.removeSidebarRegistry(this.componentName)

		// Unsubscribe from all subscriptions
		this._unSubscribeAll.next(null)
		this._unSubscribeAll.complete()
	}
}
