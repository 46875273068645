import { Component, ContentChild, Input, TemplateRef } from '@angular/core'

@Component({
	selector: 'app-table-column',
	templateUrl: './table-column.component.html'
})
export class TableColumnComponent {

	@Input() label: string
	@Input() prop: string
	@Input() sortable: boolean = false
	@ContentChild(TemplateRef) columnTemplate: TemplateRef<any>
	@ContentChild('headerTemplate') headerTemplate: TemplateRef<any>

	constructor() { }

}
