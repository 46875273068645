import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
	selector: 'app-counter',
	templateUrl: './counter.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CounterComponent),
			multi: true
		}
	]
})
export class CounterComponent {

	@Input() min = -Infinity
	@Input() max = Infinity
	@Input() stepPlus: number | undefined
	@Input() stepMinus: number | undefined
	@Input() isDisabled = false
	@Input() placeholder = '0'
	@Output() counterChange: EventEmitter<number> = new EventEmitter<number>()

	private _defaultStepPlus = 0.5
	private _defaultStepMinus = 0.5

	public value = 0

	constructor() {}

	private clearValue() {
		this.value = 0
		this.onChange(this.value)
		this.counterChange.emit(this.value)
	}

	onChange: any = () => {}
	onTouched: any = () => {}

	writeValue(value: number): void {
		if (value !== undefined) {
			this.value = value
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn
	}

	setDisabledState?(isDisabled: boolean): void {}

	public increment(): void {
		if (this.value < this.max) {
			this.value += this.stepPlus || this._defaultStepPlus
			if (this.value > this.max) {
				this.value = this.max
			}
			this.onChange(this.value)
			this.counterChange.emit(this.value)
		}
	}

	public decrement(): void {
		if (this.value > this.min) {
			this.value -= this.stepMinus || this._defaultStepMinus
			if (this.value < this.min) {
				this.value = this.min
			}
			this.onChange(this.value)
			this.counterChange.emit(this.value)
		}
	}

	public onInput(event: any): void {
		const value: number = +event?.target?.value
		if (!value) return
		this.onChange(this.value)
		this.counterChange.emit(this.value)
	}

	public onFocusOut() {
		let newValue: number = this.value
		if (newValue < this.min) {
			newValue = this.min
		} else if (newValue > this.max) {
			newValue = this.max
		}
		this.value = newValue
		this.onChange(this.value)
		this.counterChange.emit(this.value)
	}

}
