
<ng-container *ngIf="show">
	<div class="loading-bar">

		<mat-progress-bar
			[mode]="mode"
			[value]="progress"></mat-progress-bar>
	</div>

</ng-container>
