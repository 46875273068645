import { NgModule } from '@angular/core'
import { EngineeringWorksComponent } from './engineering-works/engineering-works.component'
import { CommonModule } from '@angular/common'

@NgModule({
	declarations: [
		EngineeringWorksComponent
	],
	imports: [
		CommonModule
	],
	exports: [],
	providers: []
})
export class IndividualPagesModule { }
