import { Injectable } from '@angular/core'
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	CanActivateChild,
	CanLoad
} from '@angular/router'

import { Observable } from 'rxjs'
import { AuthService } from '../services/auth.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

	constructor(
		private authService: AuthService,
		private router: Router
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		const redirectUrl = state.url === '/sign-out' ? '/' : state.url
		return this.checkAuthentication(redirectUrl)
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const redirectUrl = state.url === '/sign-out' ? '/' : state.url
		return this.checkAuthentication(redirectUrl)
	}

	canLoad(): Observable<boolean> | Promise<boolean> | boolean {
		return this.checkAuthentication('/')
	}

	private checkAuthentication(redirectURL: string): boolean {
		if (!this.authService.checkAuth()) {
			this.router.navigate(['/auth/sign-in'], { queryParams: { redirectURL } })
			return false
		}

		return true
	}
}
