import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class ScrollService {

	constructor() {}

	disableScroll() {
		document.body.style.overflow = 'hidden'
	}

	enableScroll() {
		document.body.style.overflow = 'auto'
	}

}
