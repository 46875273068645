import { Injectable, OnDestroy } from '@angular/core'
import { MarketStatusEnum } from '../enums/market-status.enum'
import {
	ClockInterface
} from '../interfaces/clock.interface'
import { BehaviorSubject, interval, map, Observable, Subject, takeUntil } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../environments/environment'

@Injectable({
	providedIn: 'root'
})
export class MarketStatusService implements OnDestroy {

	private _unSubscribeAll: Subject<any> = new Subject<any>()

	public marketStatus$: BehaviorSubject<MarketStatusEnum | null> = new BehaviorSubject<MarketStatusEnum | null>(null)
	public marketClock$: BehaviorSubject<ClockInterface | null> = new BehaviorSubject<ClockInterface | null>(null)

	constructor(
		private _http: HttpClient
	) {}

	// Ищет и устанавливает статус рынка по датам
	private getMarketStatus(marketClock: ClockInterface): MarketStatusEnum | null {
		if (!marketClock) return null
		const currentDateTime: Date = new Date()
		const nextOpenDate: Date = new Date(marketClock.next_open)
		const nextCloseDate: Date = new Date(marketClock.next_close)

		if (currentDateTime >= this.getStartPreMarket(marketClock) && currentDateTime <= nextOpenDate) {
			return MarketStatusEnum.preMarket
		} else if (currentDateTime >= nextCloseDate && currentDateTime <= this.getFinishPostMarket(marketClock)) {
			return MarketStatusEnum.postMarket
		} else if (marketClock.is_open) {
			return MarketStatusEnum.marketOpen
		} else if (currentDateTime >= nextOpenDate && currentDateTime <= nextCloseDate) {
			return MarketStatusEnum.marketOpen
		} else {
			return MarketStatusEnum.marketClose
		}
	}

	// Вернет начало пре-маркета
	public getStartPreMarket(marketClock: ClockInterface): Date {
		const nextOpenDate = new Date(marketClock.next_open)
		nextOpenDate.setHours(nextOpenDate.getHours() - 5)
		nextOpenDate.setMinutes(nextOpenDate.getMinutes() - 30)
		return nextOpenDate
	}

	// Вернет конец пост-маркета
	public getFinishPostMarket(marketClock: ClockInterface): Date {
		const nextCloseDate = new Date(marketClock.next_close)
		nextCloseDate.setHours(nextCloseDate.getHours() + 4)
		return nextCloseDate
	}

	// Следит за статусом и временем рынка
	public watchMarketClock(marketClock: ClockInterface): void {
		this.marketClock$.next(marketClock)
		this.marketStatus$.next(this.getMarketStatus(marketClock))
		interval(60000)
			.pipe(takeUntil(this._unSubscribeAll))
			.subscribe(() => {
				this.marketClock$.next(marketClock)
				this.marketStatus$.next(this.getMarketStatus(marketClock))
			})
	}

	public getMarketClock(): Observable<ClockInterface> {
		return this._http.get<ClockInterface>(`${ environment.apiUrl }/proxy_api/v1/alpaca/get_market_clock/`)
			.pipe(
				map((response: ClockInterface) => {
					return {
						...response,
						timestamp: new Date(response.timestamp),
						next_open: new Date(response.next_open),
						next_close: new Date(response.next_close)
					}
				})
			)
	}

	ngOnDestroy() {
		this._unSubscribeAll.next(null)
		this._unSubscribeAll.complete()
	}

}
