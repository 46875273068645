import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {
	transform(value: string | undefined, maxLength: number): string | null {
		if (!value) return null
		if (value?.length <= maxLength) {
			return value
		}
		return value.substr(0, maxLength) + '...'
	}
}
