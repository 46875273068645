import { NgModule } from '@angular/core'
import { SlTpSidebarComponent } from './sl-tp-sidebar.component'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgxSliderModule } from 'ngx-slider-v2'
import { CorePipesModule } from '../../../../pipes/pipes.module'
import { AssetsService } from '../../../asset-entity/services/assets.service'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { CounterModule } from '../../../../components/counter/counter.module'
import { TableModule } from '../../../../components/table/table.module'
import { OrderPipesModule } from '../../../order-entity/pipes/order-pipes.module'
import {
	ConfirmCancelOrderSidebarModule
} from '../../../order-entity/components/sidebars/confirm-cancel-order-sidebar/confirm-cancel-order-sidebar.module'
import { CoreSidebarModule } from '../../../../components/core-sidebar/core-sidebar.module'

@NgModule({
	declarations: [
		SlTpSidebarComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		NgxSliderModule,
		TableModule,
		CorePipesModule,
		NgxSkeletonLoaderModule,
		CounterModule,
		FormsModule,
		OrderPipesModule,
		ConfirmCancelOrderSidebarModule,
		CoreSidebarModule
	],
	exports: [
		SlTpSidebarComponent
	],
	providers: [
		AssetsService
	]
})
export class SlTpSidebarModule { }
