import { Injectable } from '@angular/core'
import { OrderInterface } from '../../order-entity/interfaces/order.interface'
import { SlTpParamsInterface } from '../interfaces/sl-tp-params.interface'
import { OrderStatusEnum } from '../../order-entity/enums/order-status.enum'
import { CalcSlTpInterface } from '../interfaces/calc-sl-tp.interface'
import { OrderGeneralHelperService } from '../../order-entity/services/order-general-helper.service'
import { StepsCounterInterface } from '../../../interfaces/steps-counter.interface'
import { OrderSideEnum } from '../../order-entity/enums/order-side.enum'
import { PositionInterface } from '../../position-entity/interfaces/position.interface'
import { PositionSideEnum } from '../../position-entity/enums/position-side.enum'
import { NotificationService } from '../../../components/notification/core/services/notification.service'
import {
	CreateOrderFromPositionInterface
} from '../../order-entity/interfaces/create-order-from-position.interface'

@Injectable({ providedIn: 'root' })
export class SlTpHelperService {

	protected readonly orderStatusEnum = OrderStatusEnum
	protected readonly positionSideEnum = PositionSideEnum
	protected readonly orderSideEnum = OrderSideEnum

	constructor(
		private _orderGeneralHelperService: OrderGeneralHelperService,
		private _notificationService: NotificationService
	) {}

	public getParamsSlTp(order: OrderInterface | undefined): SlTpParamsInterface {
		if (!order) return { stopLoss: { active: false, value: 0 }, takeProfit: { active: false, value: 0 } }

		const stopLoss: OrderInterface | undefined = this._orderGeneralHelperService.findStopLoss(order)
		const takeProfit: OrderInterface | undefined = this._orderGeneralHelperService.findTakeProfit(order)

		const checkStatus = (order: OrderInterface | undefined): boolean => {
			if (!order) return false
			return [this.orderStatusEnum.new, this.orderStatusEnum.held].includes(order.status)
		}

		return {
			stopLoss: {
				active: checkStatus(stopLoss),
				value: stopLoss ? stopLoss.stop_price : 0
			},
			takeProfit: {
				active: checkStatus(takeProfit),
				value: takeProfit ? takeProfit.limit_price : 0
			}
		}
	}

	public calcSlTp(isShort: boolean, qty: number, targetPrice: number, field: 'percent' | 'price' | 'dollar', value: number): CalcSlTpInterface {
		const factor: number = isShort ? -1 : 1
		let percent = 0
		let price = 0
		let dollar = 0
		qty = qty === 0 ? 1 : qty

		switch (field) {
		case 'percent':
			percent = value
			price = targetPrice + (targetPrice * percent / 100) * factor
			dollar = targetPrice * percent / 100 * qty
			break
		case 'price':
			price = value
			percent = (price - targetPrice) / targetPrice * 100 * factor
			dollar = (price - targetPrice) * qty * factor
			break
		case 'dollar':
			dollar = value
			price = targetPrice + (dollar / qty) * factor
			percent = (price - targetPrice) / targetPrice * 100 * factor
			break
		}

		return {
			percent: parseFloat(percent?.toFixed(2)),
			price: parseFloat(price.toFixed(price >= 1 ? 2 : 4)),
			dollar: parseFloat(dollar?.toFixed(2))
		}
	}

	public getStepByPrice(price: number): StepsCounterInterface {
		if (price === 1000) return { stepPlus: 1, stepMinus: 0.1 }
		if (price > 1000) return { stepPlus: 1, stepMinus: 1 }
		if (price === 1) return { stepPlus: 0.1, stepMinus: 0.01 }
		if (price < 1000 && price > 1) return { stepPlus: 0.1, stepMinus: 0.1 }
		return { stepPlus: 0.001, stepMinus: 0.001 }
	}

	public filterOrdersByPosition(position: PositionInterface | undefined, orders: OrderInterface[] | undefined): OrderInterface[] {
		if (!position || !orders) return []
		const positionSymbol: string = (position.symbol || '').toUpperCase()
		const transformedSide: OrderSideEnum = position?.side === this.positionSideEnum.long ?
			this.orderSideEnum.sell :
			this.orderSideEnum.buy

		return orders
			.flatMap((order: OrderInterface) => [order, ...(order.children_order || [])])
			.filter((order: OrderInterface) =>
				order.symbol.toUpperCase() === positionSymbol &&
				order.side === transformedSide &&
				order.status !== this.orderStatusEnum.held
			)
	}

	public calcQtyAvailable(position: PositionInterface | undefined, orders: OrderInterface[] | undefined): number {
		if (!position || !orders) return 0
		const sumQtyFromOrders: number = orders.reduce((sum: number, order: OrderInterface) => {
			if (position.side === this.positionSideEnum.short) {
				return order.side === this.orderSideEnum.buy ? sum + order.qty : sum
			}

			return order.side === this.orderSideEnum.sell ? sum + order.qty : sum
		}, 0)
		const diff: number = position.side === this.positionSideEnum.short ?
			+position.qty + sumQtyFromOrders :
			+position.qty - sumQtyFromOrders
		return Math.floor(Math.abs(diff))
	}

	public checkIsValidOrder(
		position: PositionInterface | undefined,
		qty: { active: number, available: number },
		stopLoss: { isActive: boolean, value: number },
		takeProfit: { isActive: boolean, value: number }
	): boolean {
		if (!position) return false
		const slDiff: number = stopLoss.value - +position.current_price
		const tpDiff: number = takeProfit.value - +position.current_price
		const isShort: boolean = position.side === this.positionSideEnum.short

		const showError = (message: string): boolean => {
			this._notificationService.onShow('error', 'Ошибка', message)
			return false
		}

		if (qty.active <= 0 || qty.active > qty.available) return showError('Количество не может быть равно 0 и больше доступных')

		let stopLossValid = true
		let takeProfitValid = true

		const isSlValueValid = (slDiff: number, slPrice: number): boolean => {
			return ((isShort && slDiff >= 0.01) || (!isShort && slDiff <= 0.01)) && slPrice >= 0.01
		}
		if (stopLoss.isActive && !isSlValueValid(slDiff, stopLoss.value)) {
			stopLossValid = false
		}

		const isTpValueValid = (tpDiff: number): boolean => {
			return (isShort && tpDiff <= 0.01) || (!isShort && tpDiff >= 0.01)
		}
		if (takeProfit.isActive && !isTpValueValid(tpDiff)) {
			takeProfitValid = false
		}

		if (!stopLossValid) return showError('Вы ввели недопустимое значение для Stop Loss')
		if (!takeProfitValid) return showError('Вы ввели недопустимое значение для Take Profit')

		return true
	}

	public collectOrderForCreate(
		position: PositionInterface,
		qty: number,
		stopLoss: { isActive: boolean, value: number },
		takeProfit: { isActive: boolean, value: number }
	): CreateOrderFromPositionInterface {
		return {
			symbol: position.symbol,
			qty: qty,
			side: position.side === this.positionSideEnum.long ? this.orderSideEnum.sell : this.orderSideEnum.buy,
			...(stopLoss.isActive && {
				stop_loss: { stop_price: stopLoss.value.toString() }
			}),
			...(takeProfit.isActive && {
				take_profit: { limit_price: takeProfit.value.toString() }
			})
		}
	}


}
