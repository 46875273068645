import { NgModule } from '@angular/core'
import { TableComponent } from './table.component'
import { TableColumnComponent } from './table-column/table-column.component'
import { CommonModule, NgTemplateOutlet } from '@angular/common'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'

@NgModule({
	declarations: [
		TableComponent,
		TableColumnComponent
	],
	imports: [
		NgTemplateOutlet,
		CommonModule,
		NgxSkeletonLoaderModule
	],
	exports: [
		TableComponent,
		TableColumnComponent
	],
	providers: []
})
export class TableModule { }
