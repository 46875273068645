import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {

	transform(value: string | undefined): string {
		if (!value) return ''

		const cleanedValue: string = value.replace(/\D/g, '')
		const format: string = cleanedValue.length === 11 ? '+0 (000) 000 00 00' : '+00 (000) 000 00 00'

		let formattedValue = ''
		let valueIndex = 0
		for (const char of format) {
			if (char === '0') {
				formattedValue += cleanedValue[valueIndex++] || ''
			} else {
				formattedValue += char
			}
		}

		return formattedValue
	}
}
