// ---  ORDERS ---> //

// details
export const orderDetailsSidebar = 'order-details-sidebar'
export const complexOrderDetailsSidebar = 'complex-order-details-sidebar'

// change
export const changeOrderSidebar = 'change-order-sidebar'
export const changeComplexOrderSidebar = 'change-complex-order-sidebar'

// confirm
export const confirmOrderSidebar = 'confirm-order-sidebar'
export const confirmComplexOrderSidebar = 'confirm-complex-order-sidebar'
export const confirmCreateOrderSidebar = 'confirm-create-order-sidebar'
export const confirmCancelOrderSidebar = 'confirm-cancel-order-sidebar'

// <---  ORDERS --- //


// ---  POSITIONS ---> //

export const slTpSidebar = 'sl-tp-sidebar'
export const closePositionSidebar = 'close-position-sidebar'
export const positionInfoSidebar = 'position-info-sidebar'

// <---  POSITIONS --- //
