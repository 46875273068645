<div class="sidebar__container">
	<div class="sidebar__wrapper">
		<div class="sidebar-slTp__content">
			<div class="sidebar-slTp__header">
				<h3 class="sidebar-slTp__header-title">Stop Loss / Take Profit</h3>
				<div class="sidebar-slTp__header-close" (click)="onClose()">
					<img src='../../../../../assets/images/icons/x.svg' alt="x">
				</div>
			</div>
			<div class="sidebar-slTp__analytics">
				<div class="sidebar-positionInfo__analytics__column">
					<div class="sidebar-positionInfo__analytics__cell">
						<span class="sidebar-positionInfo__analytics__cell-title">Тикер</span>
						<span class="sidebar-positionInfo__analytics__cell-value">{{position?.symbol}}</span>
					</div>
					<div class="sidebar-positionInfo__analytics__cell">
						<span class="sidebar-positionInfo__analytics__cell-title">Позиция</span>
						<span class="sidebar-positionInfo__analytics__cell-value">{{position?.side | uppercase}}</span>
					</div>
				</div>
				<div class="sidebar-positionInfo__analytics__column">
					<div class="sidebar-positionInfo__analytics__cell">
						<span class="sidebar-positionInfo__analytics__cell-title">Цена</span>
						<span class="sidebar-positionInfo__analytics__cell-value">{{position?.current_price | currency}}</span>
					</div>
					<div class="sidebar-positionInfo__analytics__cell">
						<span class="sidebar-positionInfo__analytics__cell-title">Средняя цена</span>
						<span class="sidebar-positionInfo__analytics__cell-value">{{position?.avg_entry_price | currency}}</span>
					</div>
				</div>
				<div class="sidebar-positionInfo__analytics__column">
					<div class="sidebar-positionInfo__analytics__cell">
						<span class="sidebar-positionInfo__analytics__cell-title">Доходность, $</span>
						<span class="sidebar-positionInfo__analytics__cell-value">{{ position ? (position.profitability_dollar | textProfitability: 'dollar') : '-' }}</span>
					</div>
					<div class="sidebar-positionInfo__analytics__cell">
						<span class="sidebar-positionInfo__analytics__cell-title">Доходность, %</span>
						<span class="sidebar-positionInfo__analytics__cell-value">{{ position ? (position.profitability_percent | textProfitability: 'percent') : '-' }}</span>
					</div>
				</div>
				<div class="sidebar-positionInfo__analytics__column">
					<div class="sidebar-positionInfo__analytics__cell">
						<span class="sidebar-positionInfo__analytics__cell-title">Всего, шт</span>
						<span class="sidebar-positionInfo__analytics__cell-value">{{totalQty}}</span>
					</div>
					<div class="sidebar-positionInfo__analytics__cell">
						<span class="sidebar-positionInfo__analytics__cell-title">Доступно, шт</span>
						<span class="sidebar-positionInfo__analytics__cell-value">{{qtyAvailable | number}}</span>
					</div>
				</div>
			</div>
			<div class="sidebar-slTp__orders" *ngIf='isVisibleOrdersTable'>
				<span class="sidebar-slTp__orders-title">Открытые ордера {{ position?.symbol }}</span>
				<div class="sidebar-slTp__orders__table">
					<app-table [items]="orders"
										 noItemsText="Нет открытых ордеров"
										 sizeBodyNoItems="small"
					>
						<app-table-column label="Тип ордера">
							<ng-template let-item="item">
								<div class="table-cell">
									<div class="table-cell">
											<span class="table-cell-value" [ngClass]="{
												'order-side-buy': item.side === orderSideEnum.buy,
												'order-side-sell': item.side === orderSideEnum.sell,
											}">{{ item.side_ru }}</span>
										<span class="table-cell-sub-value order-cell-type table-cell-text-light">{{ item.type | extendedOrderType: item.order_class : item.order_attribute }}</span>
									</div>
								</div>
							</ng-template>
						</app-table-column>
						<app-table-column label="Stop / Limit">
							<ng-template let-item="item">
								<div class="table-cell">
									<span class="table-cell-value">{{ item.qty }}</span>
									<span class='table-cell-sub-value table-cell-text-light' *ngIf='item.type === orderTypeEnum.market'>
										{{item.price | currency}}
									</span>
									<div class="table-cell-row table-cell-row-gap table-cell-row-end" *ngIf='item.type !== orderTypeEnum.market'>
										<span class="table-cell-sub-value table-cell-text-light" *ngIf="[orderTypeEnum.stop, orderTypeEnum.stop_limit].includes(item.type)">
											S: {{ item.stop_price | currency }}
										</span>
										<span class="table-cell-sub-value table-cell-text-light" *ngIf="[orderTypeEnum.limit, orderTypeEnum.stop_limit].includes(item.type)">
											L: {{ item.limit_price | currency }}
										</span>
									</div>
								</div>
							</ng-template>
						</app-table-column>

						<app-table-column label="Статус / срок">
							<ng-template let-item="item">
								<div class="table-cell">
									<span class="table-cell-value">{{ item.status_ru }}</span>
									<span
										class="table-cell-sub-value order-cell-term table-cell-text-light">{{ item.time_in_force }}</span>
								</div>
							</ng-template>
						</app-table-column>

						<app-table-column label="Действия">
							<ng-template let-item="item" let-isNested="isNested">
								<div class="table-cell-actions" *ngIf="!isNested">
									<div class="table-cell-actions-item table-cell-actions-item-danger" title="Отменить"
											 (click)="openConfirmCancelOrder(item)">
										<img src='../../../../../assets/images/icons/close-danger.svg' alt="close">
									</div>
								</div>
							</ng-template>
						</app-table-column>
					</app-table>
				</div>
			</div>
			<div class="sidebar-slTp__settings">
				<div class="sidebar-slTp__settings__amount">
					<div class="sidebar-slTp__settings__amount__range">
						<ngx-slider [(value)]='activeSliderValue'
												[options]="rangeOptions"
												(userChange)='onChangeSlider()'
						></ngx-slider>
					</div>
					<div class="sidebar-slTp__settings__amount__inputs">
						<div class="form-control form-control-btn-after form-control-sm" [class.form-control-disabled]='!isActiveRange'>
							<input
								type="number"
								class="input-control disable-input-arrow"
								placeholder="В процентах"
								[(ngModel)]='activePercent'
								(ngModelChange)='onChangePercent()'
								[disabled]='!isActiveRange'
							>
							<span class="btn-after">
								<span class="sidebar-slTp__settings__amount__inputs-mark">%</span>
							</span>
						</div>
						<div class="form-control form-control-btn-after form-control-sm" [class.form-control-disabled]='!isActiveRange'>
							<input
								type="number"
								class="input-control disable-input-arrow"
								placeholder="В количестве"
								[(ngModel)]='activeQty'
								(ngModelChange)='onChangeQty()'
								[disabled]='!isActiveRange'
							>
							<span class="btn-after">
								<span class="sidebar-slTp__settings__amount__inputs-mark">шт.</span>
							</span>
						</div>
					</div>
				</div>

				<div class='sidebar-slTp__settings__slTp'>
					<div class='sidebar-slTp__settings__slTp__column' [formGroup]="stopLossForm">
						<div class='sidebar-slTp__settings__slTp__column__title'>
							<span class='sidebar-slTp__settings__slTp__column__title-value'>Stop Loss</span>
							<div class="switch">
								<input class="switch-input" id="switch-sl-sidebar" type="checkbox"
											 [checked]='stopLossActive'
											 (input)="toggleActiveSlTp('stopLoss')"
								>
								<label class="switch-label" for="switch-sl-sidebar"></label>
							</div>
						</div>
						<div class="sidebar-slTp__settings__slTp__column__counters">
							<app-counter formControlName="percent"
													 [isDisabled]="!stopLossActive"
													 (counterChange)="changeSlTp('stopLoss', 'percent')"
							></app-counter>
							<app-counter formControlName="price"
													 [isDisabled]="!stopLossActive"
													 (counterChange)="changeSlTp('stopLoss', 'price')"
													 [stepPlus]="stepsPricesStopLoss.stepPlus"
													 [stepMinus]="stepsPricesStopLoss.stepMinus"
							></app-counter>
							<app-counter formControlName="dollar"
													 [isDisabled]="!stopLossActive"
													 (counterChange)="changeSlTp('stopLoss', 'dollar')"
							></app-counter>
						</div>
					</div>

					<div class='sidebar-slTp__settings__slTp__column'>
						<div class="sidebar-slTp__settings__slTp__column__types">
							<span class="sidebar-slTp__settings__slTp__column__types-title">%</span>
							<span class="sidebar-slTp__settings__slTp__column__types-title">Цена</span>
							<span class="sidebar-slTp__settings__slTp__column__types-title">$</span>
						</div>
					</div>

					<div class='sidebar-slTp__settings__slTp__column' [formGroup]="takeProfitForm">
						<div class='sidebar-slTp__settings__slTp__column__title'>
							<span class='sidebar-slTp__settings__slTp__column__title-value'>Take Profit</span>
							<div class="switch">
								<input class="switch-input" id="switch-tp-sidebar" type="checkbox"
											 [checked]='takeProfitActive'
											 (input)="toggleActiveSlTp('takeProfit')"
								>
								<label class="switch-label" for="switch-tp-sidebar"></label>
							</div>
						</div>
						<div class="sidebar-slTp__settings__slTp__column__counters">
							<app-counter formControlName="percent"
													 [isDisabled]="!takeProfitActive"
													 (counterChange)="changeSlTp('takeProfit', 'percent')"
							></app-counter>
							<app-counter formControlName="price"
													 [isDisabled]="!takeProfitActive"
													 (counterChange)="changeSlTp('takeProfit', 'price')"
													 [stepPlus]="stepsPricesTakeProfit.stepPlus"
													 [stepMinus]="stepsPricesTakeProfit.stepMinus"
							></app-counter>
							<app-counter formControlName="dollar"
													 [isDisabled]="!takeProfitActive"
													 (counterChange)="changeSlTp('takeProfit', 'dollar')"
							></app-counter>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="sidebar-slTp__btns">
			<button type="button" class="btn btn-md btn-solid-gray btn-direct" (click)="onClose()">Отменить</button>
			<button type="button" class="btn btn-md btn-primary btn-direct" (click)='createOrder()'>Подтвердить</button>
		</div>
	</div>
</div>
