import { NgModule } from '@angular/core'
import { ExtendedOrderTypePipe } from './extended-order-type.pipe'
import { ExtendedNestedOrderTypePipe } from './extended-nested-order-type.pipe'
import { ExtendedOrderTermPipe } from './extended-order-term.pipe'
import { StubSymbolPipe } from './stub-symbol.pipe'
import { ExtendedOrderClassPipe } from './extended-order-class.pipe'
import { TitleOrderTermPipe } from './title-order-term.pipe'

@NgModule({
	declarations: [
		ExtendedOrderTypePipe,
		ExtendedNestedOrderTypePipe,
		ExtendedOrderTermPipe,
		StubSymbolPipe,
		ExtendedOrderClassPipe,
		TitleOrderTermPipe
	],
	imports: [],
	exports: [
		ExtendedOrderTypePipe,
		ExtendedNestedOrderTypePipe,
		ExtendedOrderTermPipe,
		StubSymbolPipe,
		ExtendedOrderClassPipe,
		TitleOrderTermPipe
	],
	providers: []
})
export class OrderPipesModule {}
