import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CoreSidebarService } from './core-sidebar.service'
import { CoreSidebarComponent } from './core-sidebar.component'

@NgModule({
	declarations: [CoreSidebarComponent],
	imports: [CommonModule],
	exports: [CoreSidebarComponent],
	providers: [CoreSidebarService]
})
export class CoreSidebarModule {}
