import { Pipe, PipeTransform } from '@angular/core'
import { OrderTermInterface } from '../interfaces/order-term.interface'
import { OrderTerms } from '../consts/order-terms'

@Pipe({
	name: 'extendedOrderTerm'
})
export class ExtendedOrderTermPipe implements PipeTransform {

	protected readonly orderTerms: OrderTermInterface[] = OrderTerms

	transform(timeInForce: string | undefined): string {
		if (!timeInForce) return '-'

		const foundTerm: OrderTermInterface | undefined = this.orderTerms.find((term: OrderTermInterface) => term.term === timeInForce)
		return foundTerm ? foundTerm.description : timeInForce
	}

}
