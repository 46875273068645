import { OrderInterface } from '../interfaces/order.interface'
import { OrderTermInterface } from '../interfaces/order-term.interface'
import { Pipe, PipeTransform } from '@angular/core'
import { OrderTerms } from '../consts/order-terms'
import { OrderTermEnum } from '../enums/order-term.enum'

@Pipe({
	name: 'titleOrderTerm'
})
export class TitleOrderTermPipe implements PipeTransform {

	protected readonly orderTerms: OrderTermInterface[] = OrderTerms
	protected readonly orderTermEnum = OrderTermEnum

	transform(order: OrderInterface | undefined): string {
		if (!order) return '-'

		const termToFind: OrderTermEnum = order.extended_hours ? this.orderTermEnum.dayPlus : order.time_in_force
		const foundOrderTerm: OrderTermInterface | undefined = this.orderTerms.find((orderTerm: OrderTermInterface) => orderTerm.term === termToFind)

		return foundOrderTerm ? foundOrderTerm.title : order.time_in_force
	}


}
