import { Pipe, PipeTransform } from '@angular/core'
import { OrderInterface } from '../interfaces/order.interface'

@Pipe({
	name: 'extendedNestedOrderType'
})
export class ExtendedNestedOrderTypePipe implements PipeTransform {

	transform(order: OrderInterface | undefined): string {
		return order ? order.order_attribute || '' : ''
	}
}
