// websocket.service.ts
import { Injectable } from '@angular/core'
import { WebSocketSubject } from 'rxjs/webSocket'
import { Observable } from 'rxjs'

@Injectable({
	providedIn: 'root'
})
export class WebSocketService {
	private socket$: WebSocketSubject<any>

	constructor() { }

	public connect(url: string): void {
		this.socket$ = new WebSocketSubject({
			url: url,
			serializer: (msg: any) => JSON.stringify(msg),
			deserializer: (e: MessageEvent) => JSON.parse(e.data)
		})
	}

	public sendMessage(message: any): void {
		this.socket$.next(message)
	}

	public onMessage(): Observable<any> {
		return this.socket$.asObservable()
	}

	public close(): void {
		this.socket$.complete()
	}
}
