import {
	Component,
	ContentChildren,
	Input, OnChanges,
	QueryList, SimpleChanges
} from '@angular/core'
import { TableColumnComponent } from './table-column/table-column.component'
import { SortKeyEnum } from './core/enums/sort-key.enum'
import { SortItems } from './core/consts/sort-items'
import { ActiveSortInterface } from './core/interfaces/active-sort.interface'

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html'
})
export class TableComponent implements OnChanges {

	@Input() items: any[] | undefined = []
	@Input() nestedProp: string
	@Input() noItemsText = 'Ничего не найдено'
	@Input() excludeBorder = false
	@Input() sizeBodyNoItems: 'default' | 'large' | 'medium' | 'small' = 'default'
	@Input() isLoading = false

	@ContentChildren(TableColumnComponent) columns: QueryList<TableColumnComponent>

	protected readonly sortKeyEnum = SortKeyEnum
	protected readonly sortItems: SortKeyEnum[] = SortItems

	public shownItems: any[] = []

	public activeSortKey: ActiveSortInterface = { prop: null, key: this.sortKeyEnum.default }

	constructor() {}

	private _convertToNumberIfPossible(value: string | number | null | undefined): number | string | null | undefined {
		return (typeof value === 'string' && !isNaN(+value)) ? +value : value
	}

	private _sortItems(prop: string | null, isAsc: boolean) {
		if (!prop || !this.items) {
			this.shownItems = this.items || []
			return
		}

		this.shownItems = [...this.items].sort((a, b) => {
			const aValue: number | string | null | undefined = this._convertToNumberIfPossible(a[prop])
			const bValue: number | string | null | undefined = this._convertToNumberIfPossible(b[prop])

			if (aValue == null) return -1
			if (bValue == null) return 1

			const comparison = (typeof aValue === 'number' && typeof bValue === 'number') ?
				aValue - bValue :
				aValue.toString().localeCompare(bValue.toString())

			return isAsc ? comparison : -comparison
		})
	}

	private _defineSort() {
		switch (this.activeSortKey.key) {
		case this.sortKeyEnum.asc: 	this._sortItems(this.activeSortKey.prop, true); break
		case this.sortKeyEnum.desc: this._sortItems(this.activeSortKey.prop, false); break
		case this.sortKeyEnum.default: this.shownItems = this.items || []; break
		default: this.shownItems = this.items || []
		}
	}

	public toggleSort(column: TableColumnComponent) {
		if (!column?.sortable) return
		const index: number = this.sortItems.indexOf(this.activeSortKey.key)
		const key: SortKeyEnum = this.sortItems[index >= (this.sortItems.length - 1) ? 0 : index + 1]
		const prop: string | null = key === this.sortKeyEnum.default ? null : (column?.prop || null)
		this.activeSortKey = { prop, key }
		this._defineSort()
	}

	public getIsVisibleSort(column: TableColumnComponent, key: SortKeyEnum): boolean {
		if (column.prop === this.activeSortKey.prop) {
			return [key, this.sortKeyEnum.default].includes(this.activeSortKey.key)
		}
		return true
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['items']?.currentValue) {
			this._defineSort()
		}
	}

}
