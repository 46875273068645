import { Component, EventEmitter, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

@Component({
	selector: 'app-confirm-close-position',
	templateUrl: './confirm-close-position.component.html'
})
export class ConfirmClosePositionComponent {

	@Output() onConfirm: EventEmitter<boolean> = new EventEmitter<boolean>()

	constructor(
		private _dialog: MatDialog
	) {}

	public confirm() {
		this.onConfirm.next(true)
	}

	public close() {
		this._dialog.closeAll()
	}

}
