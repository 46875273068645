import { Routes } from '@angular/router'
import { AuthGuard } from './modules/auth/guards/auth.guard'
import { NoAuthGuard } from './modules/auth/guards/no-auth.guard'
import {
	EngineeringWorksComponent
} from './modules/individual-pages/engineering-works/engineering-works.component'
import { UserDetailResolver } from '../core/entities/user-entity/resolvers/user-detail.resolver'

export const appRouting: Routes = [
	{
		path: '',
		redirectTo: '/cabinet/briefcase',
		pathMatch: 'full'
	},
	{
		path: 'auth',
		loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard]
	},
	{
		path: 'verify',
		loadChildren: () => import('./modules/verify/verify.module').then((m) => m.VerifyModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard]
	},
	{
		path: 'cabinet',
		loadChildren: () => import('./modules/cabinet/cabinet.module').then((m) => m.CabinetModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		resolve: {
			userDetail: UserDetailResolver
		}
	},
	{
		path: 'engineering-works',
		component: EngineeringWorksComponent,
		pathMatch: 'full'
	}
]
