<div class="sidebar__container">
	<div class="sidebar__wrapper">
		<div>
			<div class="sidebar-cancelOrder__header">
				<h3 class="sidebar-cancelOrder__header-title">Подтвердите отмену</h3>
				<div class="sidebar-cancelOrder__header-close" (click)="onClose()">
					<img src="../../../../../../assets/images/icons/x.svg" alt="x">
				</div>
			</div>

			<div class="sidebar-cancelOrder__content">
				<div class="remark">
					<p class="remark-item">
						Вы согласны с тем, что ордер будет отменен без возможности восстановления?
					</p>
				</div>

				<div class="sidebar-cancelOrder__content__order">
					<div class="sidebar-cancelOrder__content__order__header">
						<span class="sidebar-cancelOrder__content__order__header-symbol">{{order?.symbol}}</span>
						<span class="sidebar-cancelOrder__content__order__header-side" [class]="colorOrderSide">{{order?.side_ru}}</span>
					</div>
					<div class="sidebar-cancelOrder__content__order__data">
						<div class="sidebar-cancelOrder__content__order__data__row">
							<span class="sidebar-cancelOrder__content__order__data__row-title">Статус</span>
							<span class="sidebar-cancelOrder__content__order__data__row-value">{{order?.status_ru}}</span>
						</div>
						<div class="sidebar-cancelOrder__content__order__data__row">
							<span class="sidebar-cancelOrder__content__order__data__row-title">Тип</span>
							<span class="sidebar-cancelOrder__content__order__data__row-value">{{order?.type | extendedOrderType}}</span>
						</div>
						<div class="sidebar-cancelOrder__content__order__data__row">
							<span class="sidebar-cancelOrder__content__order__data__row-title">Количество</span>
							<span class="sidebar-cancelOrder__content__order__data__row-value">{{order?.qty || '-'}}</span>
						</div>
						<div class="sidebar-cancelOrder__content__order__data__row" *ngIf='isVisibleStopPrice'>
							<span class="sidebar-cancelOrder__content__order__data__row-title">Stop Цена</span>
							<span class="sidebar-cancelOrder__content__order__data__row-value">{{order?.stop_price | currency}}</span>
						</div>
						<div class="sidebar-cancelOrder__content__order__data__row" *ngIf='isVisibleLimitPrice'>
							<span class="sidebar-cancelOrder__content__order__data__row-title">Limit Цена</span>
							<span class="sidebar-cancelOrder__content__order__data__row-value">{{order?.limit_price | currency}}</span>
						</div>
						<div class="sidebar-cancelOrder__content__order__data__row" *ngIf='paramsSlTp.stopLoss.active'>
							<span class="sidebar-cancelOrder__content__order__data__row-title">Stop Loss</span>
							<span class="sidebar-cancelOrder__content__order__data__row-value">{{paramsSlTp.stopLoss.value | currency}}</span>
						</div>
						<div class="sidebar-cancelOrder__content__order__data__row" *ngIf='paramsSlTp.takeProfit.active'>
							<span class="sidebar-cancelOrder__content__order__data__row-title">Take Profit</span>
							<span class="sidebar-cancelOrder__content__order__data__row-value">{{paramsSlTp.takeProfit.value | currency}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="sidebar-cancelOrder__btns">
			<button type="button" class="btn btn-lg btn-dark" (click)="cancelOrder(order)">Подтвердить</button>
			<button type="button" class="btn btn-lg btn-solid-gray" (click)="onClose()">Отмена</button>
		</div>
	</div>
</div>

