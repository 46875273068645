<div class="engineering-works">
	<div class="engineering-works__wrapper">
		<div class="engineering-works__left">
			<div class="image-container engineering-works-brand">
				<img src="./assets/images/logo-dark.svg" alt="Investlink - Dark">
			</div>
			<h1 class="engineering-works-title">Технические работы</h1>
			<div class="engineering-works__descriptions">
				<p class="engineering-works__descriptions-item">
					Сейчас мы работаем над улучшением нашего сервера для обеспечения  нашего сервера для обеспечения большей скорости и безопасности сервиса.
				</p>
				<p class="engineering-works__descriptions-item">
					Пожалуйста, попробуйте войти в систему позже.
				</p>
				<p class="engineering-works__descriptions-item">
					Приносим извинения за возможные неудобства и благодарим вас за терпение.
				</p>
			</div>
			<p class="engineering-works-author">
				С уважением,
				<span class="engineering-works-author-name">Команда Investlink</span>
			</p>
		</div>

		<div class="engineering-works__right">
			<div class="image-container engineering-works-illustration">
				<img src="./assets/images/illustrations/engineering-works.svg" alt="engineering-works">
			</div>
		</div>
	</div>
</div>
