<div class="ng-select-group {{classes}}" [class.ng-select-group-opened]="isOpen">
	<label class="ng-select-group-label" for="select">{{label}}</label>
	<ng-select
		id="select"
		[items]="items"
		[bindLabel]="bindLabel"
		[bindValue]="bindValue"
		[(ngModel)]="ngModel"
		[clearable]="clearable"
		(ngModelChange)="onNgSelectChange($event)"
		(open)="onSelectOpen()"
		(close)="onSelectClose()"
	></ng-select>
	<div class="ng-select-group-arrow">
		<img src='../../../assets/images/icons/arrow-bottom.svg' alt="arrow">
	</div>
</div>
