import { Injectable } from '@angular/core'
import { BehaviorSubject, Subscription, timer } from 'rxjs'
import { NotificationInterface } from '../interfaces/notification.interface'
import { NotificationTypesType } from '../types/notification-types.type'

@Injectable()
export class NotificationService {

	private hideTimeout: Subscription | null = null

	public notificationDataSignal: BehaviorSubject<NotificationInterface | null> = new BehaviorSubject<NotificationInterface | null>(null)
	public notificationOpenSignal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

	constructor() {}

	public onShow(type: NotificationTypesType, title: string, message: string): void {
		if (this.hideTimeout) {
			this.hideTimeout.unsubscribe()
		}

		this.notificationDataSignal.next({ type, title, message })
		this.notificationOpenSignal.next(true)

		this.hideTimeout = timer(5000)
			.subscribe(() => {
				this.onHide()
			})
	}

	public onHide() {
		this.notificationOpenSignal.next(false)
		if (this.hideTimeout) {
			this.hideTimeout.unsubscribe()
		}
	}

}
